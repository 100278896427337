import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { TodoClient, Todos } from "./TodoClient";
import styles from "./Todo.module.css";
import { Hanko } from "@teamhanko/hanko-elements";
import { SessionExpiredModal } from "./SessionExpiredModal";
import Version from "./version"

const todoAPI = process.env.REACT_APP_TODO_API!;
const hankoAPI = process.env.REACT_APP_HANKO_API!;

function Todo() {
  const navigate = useNavigate();
  const hankoClient = useMemo(() => new Hanko(hankoAPI), []);
  const [todos, setTodos] = useState<Todos>([]);
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<Error | null>(new Error("Loading......"));
  const todoClient = useMemo(() => new TodoClient(todoAPI), []);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const redirectToLogin = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const redirectToProfile = () => {
    navigate("/profile");
  };

  const redirectToRole = () => {
    navigate("/role");
  };

  const addTodo = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const user = await hankoClient.user.getCurrent(); //need to make async
    console.info(`id: ${user.id}, email: ${user.email}`);
    const todo = { description, checked: false, user: user.email };

    todoClient
      .addTodo(todo)
      .then((res) => {
        if (res.status === 401) {
          modalRef.current?.showModal();
          return;
        }
        if (res.status === 402 ) {
          setError(new Error("user: " +user.email + " not authorized"));
        }
        if (res.status === 404) {
          setError(new Error("Invalid format !!"));
          return;
        }
        setDescription("");
        listTodos();

        return;
      })
      .catch(setError);
  };

  const listTodos = useCallback(async () => {
    // const user = await hankoClient.user.getCurrent(); //need to make async

    const {id, email} = await hankoClient.user.getCurrent();
    console.log("getToken for email: " + email);

        if (email===undefined) {
          setError(new Error(" email was not available from hanko"))
          return null
        }


    todoClient
      .listTodos(email)
      .then((res) => {
        if (res.status === 401) {
          modalRef.current?.showModal();
          return;
        }
        if (res.status === 402 ) {
          setError(new Error(email + " not authorized"));
          return null
        }
        return res.json();
      })
      .then((todo) => {
        if (todo) {
          setTodos(todo);
          setError(null)
        }
      })
      .catch(setError);
  }, [todoClient]);

  const patchTodo = (id: string, checked: boolean) => {
    todoClient
      .patchTodo(id, checked)
      .then((res) => {
        if (res.status === 401) {
          modalRef.current?.showModal();
          return;
        }

        listTodos();

        return;
      })
      .catch(setError);
  };

  const deleteTodo = (id: string) => {
    todoClient
      .deleteTodo(id)
      .then((res) => {
        if (res.status === 401) {
          modalRef.current?.showModal();
          return;
        }

        listTodos();

        return;
      })
      .catch(setError);
  };

  const logout = () => {
    hankoClient.user.logout().catch(setError);
  };

  const changeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {

    const addr =event.currentTarget.value
    const newrc=addr.toLowerCase().trim()
    const newd =newrc.replace(/ /g,'');
    // console.log(addr)
    // console.log(newd)
    setDescription(newd);
    setError(null);
    if (event.currentTarget.value.length>0) {
      const filter = event.currentTarget.value
      // console.log(todos)
      // console.log(todos.filter(t => t.description.includes(filter)));
      setTodos(todos.filter(t => t.description.includes(filter)))
    } else {
      listTodos();
    }

  };

  const changeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event;
    patchTodo(currentTarget.value, currentTarget.checked);
  };

  useEffect(() => {
    if (hankoClient.session.isValid()) {
      listTodos();
    } else {
      redirectToLogin();
    }
  }, [hankoClient, listTodos, redirectToLogin]);

  useEffect(
    () => hankoClient.onUserLoggedOut(() => redirectToLogin()),
    [hankoClient, redirectToLogin]
  );

  useEffect(
    () => hankoClient.onSessionExpired(() => modalRef.current?.showModal()),
    [hankoClient]
  );

  return (
    <>
      <SessionExpiredModal ref={modalRef} />
      <nav className={styles.nav}>
        <button onClick={logout} className={styles.button}>
          Logout
        </button>
        <button onClick={redirectToProfile} className={styles.button}>
          Profile
        </button>
        <button onClick={redirectToRole} className={styles.button}>
        Permissions
        </button>
        <button disabled className={styles.button}>
          IP Address
        </button>
      </nav>
      <div className={styles.content} style={{width:'300px'}}>
        <h1 className={styles.headline}>  IP Address</h1>
        <Version />
        <div className={styles.error}>{error?.message}</div>
        <form onSubmit={addTodo} className={styles.form}>
          <input style={{width:'230px'}}
            required
            type={"text"}
            value={description}
            onChange={changeDescription}
            placeholder="Enter a valid IP address to start"
          />
          <button type={"submit"} className={styles.button}>
            +
          </button>
        </form>
        <div className={styles.list}>
          {todos.map((todo, index) => (
            <div className={styles.item} key={index}>
              <input
                readOnly
                onChange={()=>{}}
                className={styles.checkbox}
                type={"checkbox"}
                value='{attested}'
                checked={false}
              />
      
             <label className={styles.description}  htmlFor={`checkbox-${index}`}>
                {todo.description}
              </label>

            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Todo;
